import React, { FC, memo } from 'react'
import { PageProps } from 'gatsby'

import { catalogAPI } from '@api/catalog'

import LayoutRegistry from '@components/layoutRegistry'
import { getLastPathElem } from '@utils/utils'

import { IAgency, IBroker, IBrokerType, ICity, IDataAndCount, IListByCity } from 'types/agency'
import { headers } from '@utils/constants'

interface IServerDataType {
  cities: ICity[]
  brokersList: IListByCity[]
  brokerTypes: IBrokerType[]
}

const BrokerByCity: FC<PageProps<object, object, unknown, IServerDataType>> = ({
  location: { pathname },
  serverData: { cities, brokersList, brokerTypes },
}) => (
  <LayoutRegistry
    data={{
      cities,
      activeData: brokersList,
      type: 'broker',
      cityIdPath: getLastPathElem(pathname),
      brokerTypes,
    }}
  />
)

export async function getServerData() {
  try {
    const cities = (await catalogAPI.getCities(false, true)) || []

    let brokersList: IListByCity[] = []

    if (cities?.length) {
      for (const city of cities) {
        const brokerData = await catalogAPI.getBrokers(false, getLastPathElem(city['@id']))

        const agencies: IDataAndCount =
          (await catalogAPI.getAgencies(false, getLastPathElem(city['@id']))) || []

        if (brokerData?.data?.length) {
          for (let i = 0; i < brokerData.data.length; i++) {
            const agencyId = (brokerData.data?.[i] as IBroker)?.agency

            if (!agencyId) continue

            const agency = await catalogAPI.getAgencyById(getLastPathElem(agencyId))

            if (agency) (brokerData.data[i] as IBroker).agencyData = agency
          }
        }

        brokerData?.data?.length &&
          brokersList.push({
            list: brokerData,
            cityId: +getLastPathElem(city['@id']),
            agencies: agencies.data as IAgency[],
          })
      }
    }

    const brokerTypes = await catalogAPI.getBrokerTypes(false)

    return {
      props: {
        cities,
        brokersList,
        brokerTypes,
      },
      headers,
    }
  } catch (e) {
    return {
      props: { cities: [], brokersList: [], brokerTypes: [] },
      headers,
    }
  }
}

export default memo(BrokerByCity)
